<template>
  <div class="detail_box">
    <div class="phone-bar">

    </div>
    <div class="top_bar">
      <div class="icon_box" @click="$router.go(-1)">
        <van-icon :name="require('../../assets/images/icon/返回@2x.png')" size="36" color="#fff" style="margin-left:3px;" />
      </div>
      <div class="icon_box">
        <van-icon :name="require('../../assets/images/icon/分享@2x.png')" size="36" color="#fff" style="margin-left:6px;" v-if="false"/>
      </div>
    </div>
    <van-swipe class="my-swipe" indicator-color="red"  :autoplay="3000" style="height:375px;">
      <van-swipe-item v-for="(image, index) in images" :key="index">
        <van-image :src="image" style="height:100vw;" fit="cover" lazy-load>
          <template v-slot:loading>
            <van-loading type="spinner" size="20" />
          </template>
        </van-image>
      </van-swipe-item>
    </van-swipe>
    
    <div class="goods_detail">
      <div class="goods_price">
        ￥{{item.original_price}}
        <span class="bought_count">已购买666次</span>
      </div>
      <div class="goods_title">{{item.title}}</div>
      <div class="goods_dsc">智能控制 次抛设计 多种给药模式</div>
    </div>
    <div class="bar" style="background-color:#F3F4F5;height:.6875rem;"></div>
    <div class="goods_attr">
      <van-cell-group>
        <van-cell is-link>
          <template #title>
            <span style="font-size:.8125rem;font-weight:bold;">已选</span>
            <span style="font-size:.8125rem;margin-left:15px;font-weight:bold;">金色 1件</span>
          </template>
        </van-cell>
        <van-cell is-link>
          <template #title>
            <div class="service_box">
              <div style="width:3em;">
                <span style="font-size:13px;font-weight:bold;">服务</span>
              </div>
              <div>
                <span class="goods_service" v-for="item in 1" :key="item">
                  <van-icon name="passed" size="13px" style="position:absolute;top:6px;left:0;" color="#F6736E"/>
                  <span style="margin-left:18px;">姐钥自营</span>
                </span>
              </div>
            </div>
          </template>
        </van-cell>
      </van-cell-group>
    </div>
    <div class="bar" style="background-color:#F3F4F5;height:.6875rem;"></div>
    <div>
      <van-image :src="item" fit="cover" v-for="(item,index) in item.images_list" :key="index" lazy-load>
        <template v-slot:loading>
            <van-loading type="spinner" size="20" />
          </template>
      </van-image>
    </div>
    <div class="buy_bar">
      <div class="buy_bar_icon" @click="$emit('callCustomer')">
        <van-icon :src="require('../../assets/svg/客服icon.svg')" style="width:1.5625rem;height:1.5625rem;pointer-events:none;" tag="embed" />
        <span style="font-size:.625rem;color:#333;">客服</span>
      </div>
      <div class="buy_bar_icon" @click="$router.push('/cart')">
        <van-icon :src="require('../../assets/svg/购物icon.svg')" style="width:1.5625rem;height:1.5625rem;pointer-events:none;" tag="embed" />
        <span style="font-size:.625rem;color:#333;">购物车</span>
      </div>
      <div class="buy_bar_btn add_cart" @click="addCart">加入购物车</div>
      <div class="buy_bar_btn buy" @click="buyRight">立即购买</div>
    </div>
    <van-action-sheet v-model="show" :round="false">
      <div class="content">
        <div class="goods_pre">
          <div class="goods_img">
            <van-image :src="item.images" style="width:100%;" fit="contain" >
              <template v-slot:loading>
            <van-loading type="spinner" size="20" />
          </template>
            </van-image>
          </div>
          <div class="goods_content">
            <div style="font-size:1rem;color:#f5736e;letter-spacing: 0.4px;">￥{{item.original_price}}</div>
            <div style="padding:5px 0;">库存999件</div>
            <div style="color:#333;">已选：金色，{{choose_count}}件</div>
          </div>
        </div>
        <div class="bar" style="background-color:#F3F4F5;height:1px;margin-top:2.0625rem;"></div>
        <div class="goods_choose">
          <div >颜色</div>
          <div class="cate-choose">
            <span
              
            >金色</span>
          </div>
          <div class="goods_count">
            <span>购买数量</span>
            <van-stepper v-model="choose_count" theme="round" button-size="18" disable-input />
          </div>
        </div>
        <div class="confirm_btn" >
          <div @click="confirmAdd">确定</div>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import MyBtn from "@/components/common/MyBtn.vue";
export default {
  components: {
    MyBtn
  },
  data() {
    return {
      value: 1,
      item: {
        images:''
      },
      items_count: 1,
      images: [
        
      ],
      order: [{}],
      show: false,
      choose_count:1
    };
  },
  created() {
    this.getGoodsDetail();
  },
  watch: {
    value(val) {
      this.items_count = val;
    }
  },
  computed: {
    price() {
      let i;
      if (!this.item) {
        return 0;
      }
      i = this.value * this.item?.original_price;
      return i;
    }
  },
  methods: {
    confirmAdd(){
      let item = this.item
      item.count  = this.choose_count
      this.$bus.$emit('add-cart',item)
      this.choose_count = 1
      this.show = false
    },
    buyRight() {
      let item = {
        id: this.item.id,
        title: this.item.title,
        original_price: this.item.original_price,
        count: this.value,
        price: this.price,
        images:this.item.images
      };
      this.$bus.$data.order = [item];
      this.$router.push("/confirmorder");
    },
    async getGoodsDetail() {
      let { data: res } = await this.$http.post("/api/goodsDetail", {
        id: this.$route.params.id
      });
      if (res.statusCode !== 0) {
        return this.$toast.fail(res.msg);
      }
      this.item = res.data;
      this.images = res.data.swiper_img
    },
    addCart() {
      this.show = true;
      
    }
  }
};
</script>

<style lang="less" scoped>
.van-cell__right-icon{
  display: none;
}
.confirm_btn{
  // position: fixed;
  // bottom: 0;
  // left: 0;
  margin-top: 14.5625rem;
  margin-bottom: 1.3125rem;
  width: 100%;
  display: flex;
  justify-content: center;
  // padding: 10px 0;
  
  // background-color:#f9f9f9;
  text-align: center;
  
  >div{
    width: 9.375rem;
    height: 2.5rem;
    letter-spacing: 1.6px;
    display: flex;
    justify-content: center;
    align-items:center;
    color: #fff;
    background-color:#f6736e;
    border-radius: 12.5rem;
    font-size: 1rem;
  }
}
.content {
  
}
.goods_pre {
  display: flex;
  margin-top: .6875rem;
  .goods_img {
    width: 5rem;
    height: 5rem;
    margin: 0 .9375rem;
    
  }
  .goods_content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    
    > div {
      text-align: left;
      font-size: 10px;
      color: #999;
    }
  }
}
.goods_choose {
  margin-top:1.5rem;
  padding: 0 .9375rem;
  
  
  text-align: left;
  font-size: 1rem;
  .cate-choose{
    margin-top: 1.25rem;
    >span{
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5rem;
      width: 5rem;
      height: 1.875rem;
      background-color: var(--fc--);
      font-size: .875rem;
      color: #fff;
    }
  }
  .goods_count{
    margin-top: 1.125rem;
    display: flex;
    justify-content: space-between;
  }
}
.service_box {
  display: flex;
}
.goods_service {
  font-size: 13px;
  // display: flex;
  // align-items: center;
  display: inline-block;
  margin-right: 15px;
  position: relative;
}
.detail_box {
  position: relative;
  
}
.top_bar {
  z-index: 666;
  position: fixed;
  top: 3.375rem;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  .icon_box {
    width: 36px;
    height: 36px;;
  }
}
.goods_attr {
  text-align: left;
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  background-color: #fff;
  height: 375px;
}

.goods_detail {
  padding: 0 .9375rem;
  text-align: left;
  .goods_price {
    font-size: 1.625rem;
    color: #F5726E;
    font-family: DINPro-Bold;
    position: relative;
    padding: .9375rem 0;

    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .bought_count {
      // position: absolute;
      // right: 0;
      // bottom: 0;
      font-size: .6875rem;
      color: #c0c0c0;
      text-align: right;
      margin-top: .7rem;
    }
  }
  .goods_title {
    width: 17.3125rem;
    font-size: .875rem;
    color:rgba(16, 16, 16, 1);
    line-height: 1.4rem;
  }
  .goods_dsc {
    font-size: .75rem;
    
    color: rgba(153, 153, 153, 1);
    padding: .5rem 0;
  }
}
.buy_bar {
  position: fixed;
  z-index: 444;
  bottom: 0;
  left: 0;
  height: 3.8125rem;
  width: 100%;
  background-color: #f9f9f9;
  display: flex;
  justify-content: space-between;
  
  padding: .6875rem 1.25rem 1.125rem 1.25rem;
  .buy_bar_icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    
  }
  .buy_bar_btn {
   width: 6.875rem;
   height: 2rem;
    padding: 6px;
    color: #fff;
    border-radius: 2rem;
    font-size: .875rem;
    letter-spacing: 1.4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .add_cart {
    background-color: #FEA274;
     
  }
  .buy {

    background-color: #F6736E;
  }
}
</style>